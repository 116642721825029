<!-- Created by henian.xu on 2018/7/20. -->

<template>
    <page>
        <!-- 表单区 -->
        <template slot="secondHeader">
            <el-form
                ref="queryForm"
                class="second-header npa-b"
                size="small"
                :inline="true"
                :model="queryFormModel"
            >
                <el-form-item
                    prop="statDateDay"
                    label="统计时间"
                >
                    <el-date-picker
                        :picker-options="$utils.CommonConfig.pickerOptions"
                        v-model="queryFormModel.statDateDay"
                        type="daterange"
                        format="yyyy-MM-dd"
                        value-format="yyyy-MM-dd"
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                    />
                </el-form-item>
                <el-form-item
                    prop="paymentType"
                    label="付款方式"
                >
                    <el-select
                        v-model="queryFormModel.paymentType"
                        multiple
                        placeholder="请选择"
                    >
                        <el-option
                            v-for="item in paymentTypeList"
                            :key="item.id"
                            :label="item.name"
                            :value="item.id + ''"
                        />
                    </el-select>
                </el-form-item>
                <el-form-item
                    prop="paymentCheckStatus"
                    label="对账状态"
                >
                    <dictionaries-picker
                        type="paymentCheckStatus"
                        v-model="queryFormModel.paymentCheckStatus"
                    />
                </el-form-item>
                <el-form-item>
                    <el-button
                        type="primary"
                        @click="onQuery()"
                    >
                        查询
                    </el-button>
                    <el-button
                        type="success"
                        @click="onReset"
                    >
                        重置
                    </el-button>
                </el-form-item>
            </el-form>
        </template>

        <!-- 内容 -->
        <div
            class="body"
            slot="body"
            v-loading="querying"
        >
            <!-- 操作 -->
            <div
                class="ma-b"
            >
                <el-button-group>
                    <el-upload
                        class="upload-demo dp-ib ma-l"
                        action="/ex/paymentCheck/importExcel"
                        :show-file-list="false"
                        accept=".xls,.xlsx"
                        :on-success="onImportSuccess"
                        :on-error="onImportError"
                        :on-progress="onImportProgress"
                        :headers="{token:$store.getters['Global/User/token']}"
                    >
                        <el-button
                            size="small"
                            type="primary"
                        >
                            上传账单
                        </el-button>
                    </el-upload>
                    excel列名:付款单号,付款渠道,付款金额,付款手续费,结算金额
                </el-button-group>
            </div>

            <!-- 表格 -->
            <el-table
                stripe
                border
                size="small"
                :data="tableData"
                style="width: 100%"
            >
                <el-table-column
                    prop="paymentSn"
                    label="商户付款单号"
                    min-width="160"
                />
                <el-table-column
                    prop="externalPaymentSn"
                    label="第三方付款单号"
                    min-width="200"
                />
                <el-table-column
                    prop="paymentTypeName"
                    label="付款方式"
                    min-width="100"
                />
                <el-table-column
                    prop="paymentChannel"
                    label="付款渠道"
                    min-width="100"
                />
                <el-table-column
                    prop="paymentAmount"
                    label="付款金额"
                    min-width="100"
                />
                <el-table-column
                    label="付款时间"
                    min-width="150"
                >
                    <template slot-scope="scope">
                        {{ scope.row.paymentTime | moment('YYYY-MM-DD HH:mm:ss') }}
                    </template>
                </el-table-column>
                <el-table-column
                    prop="poundageRate"
                    label="渠道手续费比例"
                    min-width="110"
                >
                    <template
                        slot-scope="scope"
                        v-if="scope.row.poundageRate"
                    >
                        {{ scope.row.poundageRate }}%
                    </template>
                </el-table-column>
                <el-table-column
                    prop="poundageAmount"
                    label="手续费金额"
                    min-width="100"
                />
                <el-table-column
                    prop="checkAmount"
                    label="实际对账金额"
                    min-width="100"
                />
                <el-table-column
                    prop="externalPaymentAmount"
                    label="第三方金额"
                    min-width="100"
                />
                <el-table-column
                    prop="externalPoundageAmount"
                    label="第三方手续费"
                    min-width="100"
                />
                <el-table-column
                    prop="externalCheckAmount"
                    label="第三方对账金额"
                    min-width="110"
                />
                <el-table-column
                    prop="paymentCheckStatusName"
                    label="对账状态"
                    min-width="100"
                />
                <el-table-column
                    label="对账时间"
                    min-width="150"
                >
                    <template slot-scope="scope">
                        {{ scope.row.paymentCheckTime | moment('YYYY-MM-DD HH:mm:ss') }}
                    </template>
                </el-table-column>
            </el-table>

            <!-- 分页 -->
            <div class="ma-t ta-r">
                <pagination
                    v-model="pagination"
                    @input="onQuery"
                />
            </div>
        </div>
    </page>
</template>

<script>
import pagesMixin from '@/mixin/layout/pageMixin';
import { queryFormList } from 'vmf/src/mixin';

export default {
    name: 'PaymentCheck',
    mixins: [pagesMixin, queryFormList],
    data() {
        return {
            queryFormModel: {
                statDateDay: '',
                paymentType: [],
                paymentCheckStatus: '',
            },
            // 表格数据
            tableData: [],
            paymentTypeList: [],
            loading: null,
        };
    },
    methods: {
        /* -- 方法 -- */
        // 请求列表数据
        getListData(queryData) {
            return this.$api.Ex.PaymentCheck.data(queryData).then(json => {
                const res = json.data;
                this.tableData = res.data;
                this.pagination = res.pagination;
                return Promise.resolve(json);
            });
        },
        onImportSuccess(response) {
            this.loading.close();
            if (response.success) {
                this.$message({
                    message: response.msg,
                    type: 'success',
                });
                this.onQuery(this.pagination);
            } else {
                this.$alert(response.msg, '错误提示', {
                    dangerouslyUseHTMLString: true,
                    confirmButtonText: '确定',
                });
            }
        },
        onImportError(err) {
            this.loading.close();
            this.$message({
                message: err,
                type: 'error',
            });
        },
        onImportProgress() {
            this.loading = this.$loading({
                lock: true,
                text: '导入中',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)',
            });
        },
        initPaymentTypeList() {
            this.$api.Sp.Shop.getShopPaymentTypeList().then(json => {
                this.paymentTypeList = json.data.data;
            });
        },
    },
    created() {
        this.queryFormModel.statDateDay = [this.$utils.Common.getSysDayDate(), this.$utils.Common.getSysDayDate()];
        this.initPaymentTypeList();
    },
};
</script>

<style lang="scss">
</style>
