<!-- Created by henian.xu on 2018/7/20. -->

<template>
    <page>
        <!-- 表单区 -->
        <template slot="secondHeader">
            <el-form
                ref="queryForm"
                class="second-header npa-b"
                size="small"
                :inline="true"
                :model="queryFormModel"
            >
                <el-form-item
                    prop="statDateDay"
                    label="统计时间"
                >
                    <el-date-picker
                        :picker-options="$utils.CommonConfig.pickerOptions"
                        v-model="queryFormModel.statDateDay"
                        type="daterange"
                        format="yyyy-MM-dd"
                        value-format="yyyy-MM-dd"
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                    />
                </el-form-item>
                <el-form-item
                    prop="paymentType"
                    label="付款方式"
                >
                    <el-select
                        v-model="queryFormModel.paymentType"
                        multiple
                        placeholder="请选择"
                    >
                        <el-option
                            v-for="item in paymentTypeList"
                            :key="item.id"
                            :label="item.name"
                            :value="item.id + ''"
                        />
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-button
                        type="primary"
                        @click="onQuery()"
                    >
                        查询
                    </el-button>
                    <el-button
                        type="success"
                        @click="onReset"
                    >
                        重置
                    </el-button>
                </el-form-item>
            </el-form>

            <el-row
                class="second-header bc-t npa-a"
                :gutter="12"
            >
                <el-col :span="4">
                    <el-card
                        shadow="hover"
                        :body-style="{ padding: '0px' }"
                    >
                        <div class="small-card">
                            <div class="icon bc-main tc-w">
                                <div class="f-icon">
                                    &#xf02c;
                                </div>
                                <div class="label">
                                    合计
                                </div>
                            </div>
                            <div class="body">
                                <div class="">
                                    金额
                                </div>
                                <div class="fs-more fw-bold">
                                    {{ allPaymentStat.paymentAmount || 0 }}
                                </div>
                                <div>(手续费 {{ allPaymentStat.paymentPoundage || 0 }})</div>
                            </div>
                        </div>
                    </el-card>
                </el-col>
                <el-col :span="4">
                    <el-card
                        shadow="hover"
                        :body-style="{ padding: '0px' }"
                    >
                        <div
                            class="small-card"
                            @click="linkOrderStat"
                        >
                            <div class="icon bc-second tc-w">
                                <div class="f-icon">
                                    &#xf02b;
                                </div>
                                <div class="label">
                                    订单销售
                                </div>
                            </div>
                            <div class="body">
                                <div class="">
                                    金额
                                </div>
                                <div class="fs-more fw-bold">
                                    {{ orderStat.payableAmount || 0 }}
                                </div>
                                <div>(手续费 {{ orderStat.paymentPoundage || 0 }})</div>
                            </div>
                        </div>
                    </el-card>
                </el-col>
                <el-col :span="4">
                    <el-card
                        shadow="hover"
                        :body-style="{ padding: '0px' }"
                    >
                        <div
                            class="small-card"
                            @click="linkPrepaidCardStat"
                        >
                            <div class="icon bc-success tc-w">
                                <div class="f-icon">
                                    &#xf02a;
                                </div>
                                <div class="label">
                                    充值卡
                                </div>
                            </div>
                            <div class="body">
                                <div class="">
                                    金额
                                </div>
                                <div class="fs-more fw-bold">
                                    {{ buyerPrepaidOrderStat.payableAmount||0 }}
                                </div>
                                <div>(手续费 {{ buyerPrepaidOrderStat.paymentPoundage || 0 }})</div>
                            </div>
                        </div>
                    </el-card>
                </el-col>
                <el-col :span="4">
                    <el-card
                        shadow="hover"
                        :body-style="{ padding: '0px' }"
                    >
                        <div
                            class="small-card"
                            @click="linkAgentOrderStat"
                        >
                            <div class="icon bc-danger tc-w">
                                <div class="f-icon">
                                    &#xf029;
                                </div>
                                <div class="label">
                                    会员销售
                                </div>
                            </div>
                            <div class="body">
                                <div class="">
                                    金额
                                </div>
                                <div class="fs-more fw-bold">
                                    {{ buyerAgentFeeOrderStat.payableAmount || 0 }}
                                </div>
                                <div>(手续费 {{ buyerAgentFeeOrderStat.paymentPoundage || 0 }})</div>
                            </div>
                        </div>
                    </el-card>
                </el-col>
                <el-col :span="4">
                    <el-card
                        shadow="hover"
                        :body-style="{ padding: '0px' }"
                    >
                        <div
                            class="small-card"
                            @click="linkAgentOrderStat"
                        >
                            <div class="icon bc-yello tc-w">
                                <div class="f-icon">
                                    &#xf006;
                                </div>
                                <div class="label">
                                    门店开店
                                </div>
                            </div>
                            <div class="body">
                                <div class="">
                                    金额
                                </div>
                                <div class="fs-more fw-bold">
                                    {{ buyerOpenStoreOrderStat.payableAmount || 0 }}
                                </div>
                                <div>(手续费 {{ buyerOpenStoreOrderStat.paymentPoundage || 0 }})</div>
                            </div>
                        </div>
                    </el-card>
                </el-col>
            </el-row>
        </template>

        <!-- 内容 -->
        <div
            class="body"
            slot="body"
            v-loading="querying"
        >
            <!-- 操作 -->
            <div
                class="ma-b"
            >
                <el-button-group>
                    <el-button
                        type="success"
                        icon="el-icon-download"
                        size="small"
                        @click="exportExcel"
                    >
                        导出excel
                    </el-button>
                </el-button-group>
            </div>

            <!-- 表格 -->
            <el-table
                stripe
                border
                size="small"
                :data="tableData"
                style="width: 100%"
            >
                <el-table-column
                    type="index"
                    width="50"
                />
                <el-table-column
                    prop="paymentSn"
                    label="付款单号"
                    min-width="100"
                />
                <el-table-column
                    label="付款时间"
                    min-width="150"
                >
                    <template slot-scope="scope">
                        {{ scope.row.paymentTime | moment('YYYY-MM-DD HH:mm:ss') }}
                    </template>
                </el-table-column>
                <el-table-column
                    prop="paymentAmount"
                    label="订单金额"
                    min-width="100"
                />
                <el-table-column
                    prop="paymentTypeName"
                    label="付款方式"
                    min-width="100"
                >
                    <template slot-scope="scope">
                        {{ scope.row.paymentTypeName }}<span v-if="scope.row.paymentComments">/{{ scope.row.paymentComments }}</span>
                    </template>
                </el-table-column>
            </el-table>

            <!-- 分页 -->
            <div class="ma-t ta-r">
                <pagination
                    v-model="pagination"
                    @input="onQuery"
                />
            </div>
        </div>
    </page>
</template>

<script>
import pagesMixin from '@/mixin/layout/pageMixin';
import { queryFormList } from 'vmf/src/mixin';

export default {
    name: 'AllPayStatList',
    mixins: [pagesMixin, queryFormList],
    data() {
        return {
            queryFormModel: {
                statDateDay: '',
                paymentType: [],
            },
            // 表格数据
            tableData: [],
            allPaymentStat: {},
            orderStat: {},
            buyerPrepaidOrderStat: {},
            buyerAgentFeeOrderStat: {},
            buyerOpenStoreOrderStat: {},
            paymentTypeList: [],
        };
    },
    methods: {
        /* -- 方法 -- */
        // 请求列表数据
        getListData(queryData) {
            return this.$api.Stat.PayStat.findAllPayStatList(queryData).then(json => {
                const res = json.data.data.orderList;
                this.tableData = res.data;
                this.pagination = res.pagination;

                this.allPaymentStat = json.data.data.allPaymentStat || {};
                this.orderStat = json.data.data.orderStat || {};
                this.buyerPrepaidOrderStat = json.data.data.buyerPrepaidOrderStat || {};
                this.buyerAgentFeeOrderStat = json.data.data.buyerAgentFeeOrderStat || {};
                this.buyerOpenStoreOrderStat = json.data.data.buyerOpenStoreOrderStat || {};
                return Promise.resolve({ data: res });
            });
        },
        /* -- 事件 --*/
        linkOrderStat() {
            let url = '/stat/payStat/payStat?paymentType=' + this.queryFormModel.paymentType;
            if (this.queryFormModel.statDateDay) {
                url +=
                    '&statDateDay=' +
                    this.queryFormModel.statDateDay[0] +
                    '&statDateDay=' +
                    this.queryFormModel.statDateDay[1];
            }
            this.$router.push(url);
        },
        linkPrepaidCardStat() {
            let url = '/stat/prepaidCardStat/buyerPrepaidOrderStatList?paymentType=' + this.queryFormModel.paymentType;
            if (this.queryFormModel.statDateDay) {
                url +=
                    '&statDateDay=' +
                    this.queryFormModel.statDateDay[0] +
                    '&statDateDay=' +
                    this.queryFormModel.statDateDay[1];
            }
            this.$router.push(url);
        },
        linkAgentOrderStat() {
            let url = '/stat/distributorStat/buyerAgentFeeOrderStatList?paymentType=' + this.queryFormModel.paymentType;
            if (this.queryFormModel.statDateDay) {
                url +=
                    '&statDateDay=' +
                    this.queryFormModel.statDateDay[0] +
                    '&statDateDay=' +
                    this.queryFormModel.statDateDay[1];
            }
            this.$router.push(url);
        },
        initPaymentTypeList() {
            this.$api.Sp.Shop.getShopPaymentTypeList().then(json => {
                this.paymentTypeList = json.data.data;
            });
        },
        exportExcel() {
            let statBeginDate = null;
            let statEndDate = null;
            let paymentType = null;
            if (this.queryFormModel.statDateDay != null && this.queryFormModel.statDateDay.length === 2) {
                statBeginDate = this.queryFormModel.statDateDay[0];
                statEndDate = this.queryFormModel.statDateDay[1];
            }
            if (Array.isArray(this.queryFormModel.paymentType)) {
                paymentType = this.queryFormModel.paymentType.join(',');
            }

            this.$http.href('/stat/payStat/exportAllPayStatExcel', {
                ...this.queryFormModel,
                paymentType: paymentType,
                statBeginDate: statBeginDate,
                statEndDate: statEndDate,
            });
        },
    },
    created() {
        this.queryFormModel.statDateDay = [this.$utils.Common.getSysDayDate(), this.$utils.Common.getSysDayDate()];
        this.initPaymentTypeList();
    },
};
</script>

<style lang="scss" scoped>
</style>
